<template>
  <div id="app">
    <AccountDeletion />
  </div>
 
</template>

<script>
import AccountDeletion from './components/AccountDeletion.vue'

export default {
  name: 'App',
  components: {
    AccountDeletion
  }
}
</script>

<style>

*{
  font-family: "Plus Jakarta Sans", sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

body{
  margin: 0;
  background: linear-gradient(180deg, #F1F1F1 16.14%, rgba(241, 241, 241, 0) 67.19%);
}

body::after, body::before{
  content: ' ';
  width: 246px;
  height: 261px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  background-size: contain;
  background-position: bottom right;
  background-image: url(./assets/bg-1.png);
}

body::after{
  width: 278px;
  left: unset;
  right: 0;
  background-position: left bottom;
  background-image: url(./assets/bg-2.png);
}

.btn {
  font-size: 14px;
  font-weight: 600;
  line-height: 17.64px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  padding: 15px;
}

@media(max-width: 900px){
  body::after, body::before{
    display: none;
  }
}

@media(max-width: 568px){
  .modal{
    width: 100%;
    margin: auto 24px;
    box-sizing: border-box;
  }

  .modal h2{
    font-size: 20px !important;
  }
}

</style>
