import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import swalPlugin from './swalPlugin';

let baseUrl="https://syndicate-network.in/api/v4";
if(process.env.NODE_ENV === 'production'){
  baseUrl = 'https://syndicate-network.in/api/v4';
}

axios.defaults.baseURL = baseUrl;

axios.interceptors.request.use(function(config) {
    config.headers['Content-Type'] = 'application/json'
    config.headers['Accept'] = 'application/json'
    config.headers['ngrok-skip-browser-warning'] = 'true'
    return config;
  }, function(err) {
    return Promise.reject(err);
  });



const app = createApp(App);
app.use(VueAxios, axios);
app.use(router); 
app.use(swalPlugin);
app.mount('#app');
