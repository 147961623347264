<template>
    <div v-if="isVisibleMessage" class="modal-backdrop">
      <div class="modal">
        <div style="margin-top: 16px;">
            <img src="@/assets/done_all.svg" />
        </div>
        <h3 v-if="title == 'AccountDelete'" class="title-color">Account Deletion Request Sent Successfully</h3>
        <p v-if="title == 'AccountDelete'" class="text-content">
          After deletion all associated data will be permanently removed from our servers within 90 days.
        </p> 

        <h3 v-if="title == 'DataDelete'" class="title-color">Data Deletion Request Sent Successfully</h3>
        <p v-if="title == 'DataDelete'" class="text-content">
          After deletion the data will be permanently removed from our servers within 90 days. Your account will remain active but empty.
        </p> 
        <div class="buttons">
          <button @click="closeModal()" class="btn btn-secondary">Close</button>
        </div>

      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      isVisibleMessage: {
        type: Boolean,
        required: true
      },
      title: {
        type: String,
        default: '',
      },
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
    }
  };
  </script>
  
  <style scoped>
  
  .modal-backdrop {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 338px;
    text-align: center;
  }
  
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .btn {
    padding: 0.9rem 1.5rem;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #EE404C;
    color: #fff;
  }
  
  .btn-secondary {
    background-color: #E6E6E6;
    color: #231F20; 
  }
  .text-content{
    font-size: 14px;
    padding-bottom: 20px;
  }
  .title-color{
    color: #16A34A;
  }
  </style>