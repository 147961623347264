<template>
    <AppHeader />
    <div class="container">
      <div class="card">
        <div >
            <img src="@/assets/warning.svg" />
        </div>
        <h2>Account Deletion</h2>
        <p class="opacity-75 mb-5">Choose an option to manage your account data</p>
  
        <div class="buttons">
          <button @click="openConformModal('AccountDelete')" class="btn btn-primary"><img src="@/assets/delete.svg" alt="Trash Icon" class="icon" />Account Deletion Request</button>
          <button @click="openConformModal('DataDelete')" class="btn btn-secondary"><img src="@/assets/database.svg" alt="Trash Icon" class="icon" />Data Deletion Request</button>
        </div>
  
        <p class="note">
          Note: Deleted data will be retained in our system for <strong>90&nbsp;days</strong> before permanent deletion.
        </p>
      </div>
      <p style="font-size: 10px; margin-bottom: 24px; margin-top: 60px; opacity: 0.3;">
        All Rights Reserved. © 2024 PropertyPistol Pvt. Ltd.
      </p>
      <ConformModal v-if="isConformModalVisible" :isVisibleConform="isConformModalVisible" :title="buttonTitle" @close="closeConformModal" @submitConform="handleSubmitConform"/>
      <NumberModal v-if="isNumberModalVisible" :isVisibleNumber="isNumberModalVisible" @close="closeNumberModal" @submitNumber="handleSubmitNumber"/>
      <OtpVerificationModal v-if="isOTPModalVisible" :isVisibleOTP="isOTPModalVisible" :title="buttonTitle" :mobileNumber="phoneNumber" @close="closeOTPModal" @submitOTP="handleSubmitOTP"/>
      <MessageModal v-if="isMessageModalVisible" :isVisibleMessage="isMessageModalVisible" :title="buttonTitle" @close="closeMessageModal"/>
    </div>
   
  </template>
  
  <script>
  import AppHeader from '@/components/AppHeader.vue';
  import ConformModal from '@/components/ConformModal.vue';
  import NumberModal from '@/components/NumberModal.vue';
  import OtpVerificationModal from '@/components/OtpVerificationModal.vue';
  import MessageModal from '@/components/MessageModal.vue';
  export default {
    name: 'AccountDeletion',
    components: {
        AppHeader,
        ConformModal,
        NumberModal,
        OtpVerificationModal,
        MessageModal
    },
    data() {
        return {
            isConformModalVisible: false,
            isNumberModalVisible: false,
            isOTPModalVisible: false,
            isMessageModalVisible: false,
            buttonTitle: '',
            phoneNumber: ''
        };
    },
    methods: {
    openConformModal(titleData) {
        this.buttonTitle = titleData;
      this.isConformModalVisible = true;
    },
    handleSubmitConform(data) {
      this.submittedData = data; 
      this.isConformModalVisible = false;
      this.isNumberModalVisible = true;
    },
    handleSubmitNumber(data) {
      this.phoneNumber = data; 
      this.isNumberModalVisible = false;
      this.isOTPModalVisible = true;
    },
    handleSubmitOTP(data) {
      this.submittedData = data; 
      this.isOTPModalVisible = false;
      this.isMessageModalVisible = true;
    },
    closeConformModal() {
      this.isConformModalVisible = false;
    },
    closeNumberModal() {
        this.isNumberModalVisible = false;
    },
    closeOTPModal() {
        this.isOTPModalVisible = false;
    },
    closeMessageModal() {
        this.isMessageModalVisible = false;
    },
    },
  };
  </script>
  
  <style scoped>
  .container {
    min-height: calc(100svh - 120px);
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .card {
    background: #fff;
    border: 1px solid rgba(227, 227, 227, 1);
    padding: 40px;
    border-radius: 12px;
    text-align: center;
    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    text-align: left;
    max-width: 413px;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 25.2px;
    color: rgba(35, 31, 32, 1);
    margin-bottom: 10px;
  }
  
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(35, 31, 32, 1);
  }

  .mb-5{
    margin-bottom: 40px;
  }

  .opacity-75{
    opacity: 0.75;
  }
  
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  
  .btn {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding: 15px;
  }
  
  .btn-primary {
    background-color: #EE404C;
    color: #fff;
    margin-bottom: 16px;
  }
  
  .btn-secondary {
    background-color: #fff;
    color: #231F20; 
    border: 1px solid rgba(230, 230, 230, 1);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.05);

  }
  
  .note {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(35, 31, 32, 1);
    opacity: 0.75;
    margin-top: auto;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-bottom: 2px;
}

@media(max-width: 768px){
  .card{
    width: initial;
    border: transparent;
    box-shadow: none;
    padding: 32px;
  }

  .logo{
    width: 171px;
    height: 35px;
  }

  .btn{
    padding: 12px 15px;
  }

  .container{
    background-color: rgba(255, 255, 255, 1);
  }
}

  </style>