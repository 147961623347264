<template>
    <div v-if="isVisibleConform" class="modal-backdrop">
      <div class="modal">
        <h2>Are you sure?</h2>
        <p v-if="title == 'AccountDelete'" class="text-content">
          This will delete your account and remove your data from our servers. Deleted data will be retained for 90&nbsp;days before permanent deletion.
        </p>
        <p v-if="title == 'DataDelete'" class="text-content">
            This will remove all your data. Your account will remain active, but empty. Deleted data will be retained for 90&nbsp;days before permanent deletion.
        </p>
        
        <div class="buttons">
          <button @click="proceedToVerification()" class="btn btn-primary">Proceed to verification</button>
          <button @click="closeModal()" class="btn btn-secondary">Cancel</button>
        </div>

      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
        isVisibleConform: {
        type: Boolean,
        required: true
      },
      title: {
        type: String,
        default: '',
      },
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      proceedToVerification() {
        this.$emit('submitConform', 'conform close');    
      },
    }
  };
  </script>
  
  <style scoped>
  .modal-backdrop {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 338px;
    text-align: left;
  }
  
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  
  .btn {
    padding: 0.9rem 1.5rem;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #EE404C;
    color: #fff;
  }
  
  .btn-secondary {
    background-color: #E6E6E6;
    color: #231F20; 
  }
  .text-content{
    font-size: 14px;
    padding-bottom: 40px;
    margin-bottom: 0;
  }
  </style>