<template>
    <div v-if="isVisibleNumber" class="modal-backdrop">
      <div class="modal">
        <h2>Enter Registered Number</h2>
        <p class="text-content">
          We will send you an OTP for verification       
        </p>

        <div class="form-group">
          <v-select class="custom" :options="countries" label="iso_code" :reduce="option => option.iso_code" v-model="selectedCode"></v-select>
        <!-- <select v-model="selectedCode" id="countries" class="country-code-dropdown">
          <option v-for="code in countries" :key="code" :value="code.iso_code">{{ code.iso_code }}</option>
        </select> -->
        <input type="text" placeholder="Enter Mobile Number" v-model="phoneNumber" class="mobile-input" />
      </div>

        <div class="buttons">
          
          <button @click="getOTP()" class="btn btn-primary">Get OTP</button>
          <button @click="closeModal()" class="btn btn-secondary">Cancel</button>
        </div>

      </div>
    </div>
  </template>
  
  <script>
  import vSelect from 'vue-select';
  import 'vue-select/dist/vue-select.css';
  export default {
    components: {
      vSelect
    },
    props: {
      isVisibleNumber: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        phoneNumber: "",
        selectedCode: "", 
        countries: [], 
      };
    },
  mounted() {
    this.fetchData();
  },
    methods: {
      fetchData() {
        this.axios.get('broker_account_deletion_requests/settings')
      .then(res => {
        this.countries = res.data.countries
        this.selectedCode = res?.data?.countries[0].iso_code;
      })
      .catch(err => console.log(err))
    },

      closeModal() {
        this.$emit('close');
      },
      getOTP() {
        if(!this.selectedCode){
          this.$swal.fire({
            title: 'Error',
            text: 'Please select country code',
            icon: 'error',
          });
          return false;
        }
        if(!this.phoneNumber){
          this.$swal.fire({
            title: 'Error',
            text: 'Please enter phone number',
            icon: 'error',
          });
          return false;
        }
        
          this.axios.post(`broker_account_deletion_requests/send_otp`, {contact_number: this.phoneNumber,iso_code: this.selectedCode})
            .then(res => {
              if(res.data.success){
                this.$emit('submitNumber', {contact_number: this.phoneNumber,iso_code: this.selectedCode});
              }   
            })
            .catch(err => {
              
              if (err.response.status === 500) {
                this.$swal.fire({
                  title: 'Error',
                  text: 'Server Error!',
                  icon: 'error',
                });
              } else {
                this.$swal.fire({
                  title: 'Error',
                  text: err.response.data.message,
                  icon: 'error',
                });
              }
            })
        
      }
    }
  };
  </script>

  <style>
    .custom.v-select{
      height: 46px;
    }

    .custom .vs__dropdown-toggle{
      padding-bottom: 0;
      height: 100%;
      width: 82px;
      padding-left: 28px;
      border: 1px solid rgba(240, 240, 240, 1);
      background: linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%);
      border-radius: 3px 0px 0px 3px;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgb3BhY2l0eT0iMC41Ij4KPG1hc2sgaWQ9Im1hc2swXzY3MjVfMTA1IiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiPgo8cmVjdCB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIGZpbGw9IiNEOUQ5RDkiLz4KPC9tYXNrPgo8ZyBtYXNrPSJ1cmwoI21hc2swXzY3MjVfMTA1KSI+CjxwYXRoIGQ9Ik0xMC4xNjUgMTMuMTY1TDUgOEgxNS4zM0wxMC4xNjUgMTMuMTY1WiIgZmlsbD0iIzIzMUYyMCIvPgo8L2c+CjwvZz4KPC9zdmc+Cg==);
      background-repeat: no-repeat;
      background-position: 8px 14px;
    }

    .custom .vs__actions{
      display: none !important;
    }

    .custom .vs__selected-options{
      position: relative;
    }

    .vs__selected{
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      color: rgba(35, 31, 32, 1);
    }

    .custom  .vs__dropdown-menu{
      margin-top: 6px;
      border: 1px solid rgba(217, 217, 217, 1);
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
      background: linear-gradient(180deg, #FFFFFF 0%, #F0F0F0 100%);
      border-radius: 3px;
      width: 80px;
      min-width: initial;
      padding: 0;
    }

    .custom  .vs__dropdown-option{
      min-width: initial;
      font-size: 14px;
      font-weight: 500;
      line-height: 19.36px;
      text-align: left;
      color: rgba(35, 31, 32, 1);
      opacity: 0.5;
      padding: 8px 6px;
      text-align: center;
      box-sizing: border-box;
      border: none;
    }

    .custom  .vs__dropdown-option--highlight{
      background-color: rgba(238, 64, 76, 1);
      color: #fff;
      opacity: 1;
      padding: 8px 6px;
      text-align: center;
    }

  </style>
  
  <style scoped>
  .modal-backdrop {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 338px;
    text-align: left;
  }
  
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  
  .btn {
    padding: 0.9rem 1.5rem;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #EE404C;
    color: #fff;
  }
  
  .btn-secondary {
    background-color: #E6E6E6;
    color: #231F20; 
  }
  .text-content{
    font-size: 14px;
    padding-bottom: 40px;
    margin-bottom: 0;
  }
  .input-box {
    width: 85%;
    margin-bottom: 15px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.9rem 1.5rem;
  }

  .form-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 3px;
    box-sizing: border-box;
  }

.country-code-dropdown {
  padding: 0.9rem 1.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100px;
  font-size: 13px;
}

.mobile-input {
  flex: 1;
  padding: 14px;
  font-size: 13px;
  border: none;
  outline: none;
  display: block;
  line-height: 18px;
  border: 1px solid rgba(240, 240, 240, 1);
  border-radius: 0 3px 3px 0;
  border-left: transparent;
  height: 46px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: rgba(35, 31, 32, 1);
}

.mobile-input::placeholder{
  color: rgba(35, 31, 32, 1);
  opacity: 0.3;
}


  </style>