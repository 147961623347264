<template>
    <header class="header">
      <div class="header-content">
        <img src="../assets/logo.svg" alt="Syndicate Logo" width="190" height="39" class="logo" />
      </div>
    </header>
  </template>
  
  <style scoped>
  .header {
    background-color: #fff; 
    padding: 0;
    text-align: center;
    position: sticky;
    top: 0;
    z-index: 100;
    border-bottom: 1px solid rgba(230, 230, 230, 1);
  }
  
  .header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 10px;
  }

  @media(max-width: 768px){
  .logo{
    width: 171px;
    height: 35px;
  }
}
  
  </style>