<template>
    <div v-if="isVisibleOTP" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <div class="modal-header">
          <h2>OTP Verification</h2>
          <p>OTP was sent to <strong>{{ mobileNumber.contact_number }}</strong> 
            <button :disabled="!isResendEnabled" @click="resendOtp()" class="resend-otp" :style="{ opacity: isResendEnabled ? 1 : 0.5 }">Resend OTP</button>
          </p>
        </div>
  
        <div class="otp-inputs">
          <input type="text" placeholder="-" maxlength="1" v-for="(otpBox, index) in 6" :key="index" v-model="otp[index]" @input="moveToNext($event, index)" />
        </div>

        <div class="buttons">
          <button @click="submitOtp()" class="btn btn-primary">{{displayText()}}</button>
          <button @click="closeModal()" class="btn btn-secondary">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  export default {
    props: {
        isVisibleOTP: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
      mobileNumber: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        otp: ['', '', '', '', '', ''], 
        isResendEnabled: false,
        seconds: 15,
        timer: null
      };
    },
    mounted() {
      this.startTimer(); 
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
      displayText(){
        return this.title == 'AccountDelete' ? 'Verify and Delete Account' : 'Verify and Delete Data';
      },
       startTimer(){
        this.timer = setInterval(() => {
          if (this.seconds > 0) {
            this.seconds--;
          } else {
            this.isResendEnabled = true;
            clearInterval(this.timer);
          }
        }, 1000);
      },
      resendOtp() {
        this.axios.post(`broker_account_deletion_requests/send_otp`, {contact_number: this.mobileNumber.contact_number,iso_code: this.mobileNumber.iso_code})
            .then(res => {
              if(res){
                this.$swal.fire({
                  title: 'Success',
                  text: res.data.message,
                  icon: 'success',
                });
              }   
              this.isResendEnabled = false;
              this.seconds = 15;
              this.startTimer();
            })
            .catch(err => {
              this.isResendEnabled = false;
              this.seconds = 15;
              this.startTimer();
              if (err.response.status === 500) {
                this.$swal.fire({
                  title: 'Error',
                  text: 'Server Error!',
                  icon: 'error',
                });
              } else {
                this.$swal.fire({
                  title: 'Error',
                  text: err.response.data.message,
                  icon: 'error',
                });
              }
            })
      },
      submitOtp() {

        const enteredOtp = this.otp.join('');
        if (enteredOtp.length === 6) {
          let data = '';

          if(this.title == 'AccountDelete'){
            data={
              contact_number: this.mobileNumber.contact_number,
              iso_code: this.mobileNumber.iso_code,
              otp: enteredOtp,
              account_deletion_request: true
            }
          }else{
            data={
              contact_number: this.mobileNumber.contact_number,
              iso_code: this.mobileNumber.iso_code,
              otp: enteredOtp,
              data_deletion_request: true
            }
          }

          this.axios.post(`broker_account_deletion_requests`, data)
            .then(res => {
              if(res.data.status){
                this.$emit('submitOTP', enteredOtp); 
              }   
            })
            .catch(err => {
              
              if (err.response.status === 500) {
                this.$swal.fire({
                  title: 'Error',
                  text: 'Server Error!',
                  icon: 'error',
                });
              } else {
                this.$swal.fire({
                  title: 'Error',
                  text: err.response.data.message,
                  icon: 'error',
                });
              }
            })
        } else {
          this.$swal.fire({
                  title: 'Error',
                  text: 'Please enter a 6-digit OTP',
                  icon: 'error',
                });
        }
      },
      moveToNext(event, index) {
        if (event.target.value.length === 1 && index < 5) {
          event.target.nextElementSibling.focus();
        }
      }
    },
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;

  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 338px;
    text-align: left;
  }
  
  .modal-header h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .modal-header p {
    font-size: 14px;
    color: gray;
    padding-bottom: 30px;
  }
  
  .modal-header .resend-otp {
    color: #F05A2A;
    font-size: 14px;
    cursor: pointer;
    float: right;
    border: none;
    background: none;
    font-weight: 500;
  }
  
  .otp-inputs {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .otp-inputs input {
    width: 10%;
    height: 50px;
    font-size: 24px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    
  }

  .otp-inputs input:focus {
    border-color: #F05A2A;
    outline: none; 
}
  
  .buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }
  
  .btn {
    padding: 0.9rem 1.5rem;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn-primary {
    background-color: #EE404C;
    color: #fff;
  }
  
  .btn-secondary {
    background-color: #E6E6E6;
    color: #231F20; 
  }
  </style>